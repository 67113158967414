import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import Prices from "../components/Prices"
import InternalCards from "../components/InternalCards"
import Ready from "../components/Ready"
import Cta from "../components/Cta"

export const PricingPageTemplate = ({
  title,
  subtitle,
  prices,
  breadcrumbs,
  isCms
}) => (
  <>
    <Header title={title} subtitle={subtitle}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 35">
        <path d="M514.114 513.7c-35.018 61.635-51.53 90.53-92.215 129.007-40.705 38.677-118.446 24.921-216.552 31.806-98.087 6.685-378.62-23.714-455.373-57.491-76.538-33.773 97-56.356 63.716-157.127-27.593-83.537 48.835-204.21 84.188-292.893C-66.769 78.318-6.19 24.447 39.241 9.71 84.674-5.026 191.448 51.157 299.977 27.844 408.288 4.527 471.537-4.214 574.522 9.594c45.566 4.906 105.045 33.436 104 124.404C676.355 322.915 547.869 276.11 522.08 318.11c-17.29 41.343 27.052 133.954-7.965 195.59z" />
      </svg>
    </Header>
    <Breadcrumbs data={breadcrumbs} />
    {isCms ? null :
      <Prices data={prices} />
    }
    <InternalCards locations services recycling />
    <Ready>
      <Cta href="https://app.re-collect.com.au/register">Get started</Cta>
      <Cta to="/contact" variant>
        Contact us
      </Cta>
    </Ready>
  </>
)

const PricingPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, subtitle, seoDesc, prices },
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  return (
    <Layout
      title="Home & Business Bottle Pickup | ReCollect | Pricing"
      seoDesc={seoDesc}
    >
      <PricingPageTemplate
        title={title}
        subtitle={subtitle}
        prices={prices}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  )
}

export default PricingPage

export const PageQuery = graphql`
  query pricingQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "pricing-page" } }) {
      frontmatter {
        title
        subtitle
        seoDesc
        prices {
          title
          subtitle
          data {
            name
            price
            data
            cta
            image {
              relativePath
            }
            url
            learnUrl
          }
          pricingPerLocation {
            state
            pricingList {
              prices
              quantities
            }
            serviceFee
          }
        }
      }
    }
  }
`
