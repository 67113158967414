import React from "react"
import styled from "@emotion/styled"

import Img from "gatsby-image"
import Cta from "./Cta"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
`

const Secondary = styled.h4`
  font-size: 2.5rem;
  font-weight: 400;
  color: #505050;
  padding: 1rem 0;
`

const Image = styled.img`
  width: 90%;
  height: 17rem;
  object-fit: contain;
`

const StyledImg = styled(Img)`
  width: 100%;
  height: 20rem;
  object-fit: contain;
`

const Paper = ({ title, image, children, ctaTo, ctaBody, cta }) => (
  <Container className="paper">
    <div>
      {image.childImageSharp ? (
        <StyledImg fluid={image.childImageSharp.fluid} />
      ) : (
        <Image src={image} alt={image} />
      )}

      <Secondary>{title}</Secondary>
      {children}
    </div>
    {ctaTo && ctaBody ? (
      ctaTo.includes("https") ? (
        <Cta variant href={ctaTo}>
          {ctaBody}
        </Cta>
      ) : (
        <Cta variant to={ctaTo}>
          {ctaBody}
        </Cta>
      )
    ) : null}
    {cta}
  </Container>
)

export default Paper
